<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <!-- Listeleme Kartı -->
    <b-card>
      <b-row>
        <b-col md="8"></b-col>
        <b-col md="2"
          ><b-button variant="danger" block @click="addTask()">
            Görev Ekle
          </b-button></b-col
        >
        <b-col md="2">
          <b-button variant="success" block @click="getTask()">
            Listele
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <DxDataGrid
            :dataSource="taskList"
            :show-borders="true"
            :remote-operations="true"
            key-expr="id"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            height="75vh"
          >
            <DxColumn dataField="name" caption="Adı" />
            <DxColumn dataField="description" caption="Açıklama" />
            <DxColumn data-field="workType" caption="Zaman Tipi">
              <DxLookup
                :data-source="workTypes"
                value-expr="value"
                display-expr="text"
              />
            </DxColumn>
            <DxColumn data-field="dutyType" caption="İşlem Tipi">
              <DxLookup
                :data-source="dutyTypes"
                value-expr="value"
                display-expr="text"
              />
            </DxColumn>
            <DxColumn data-field="specialType" caption="Özel İşlem/Zaman Tipi">
              <DxLookup
                :data-source="specialTypes"
                value-expr="value"
                display-expr="text"
              />
            </DxColumn>
            <DxColumn dataField="sorguParams" caption="Sorgu Parametreleri" />
            <DxColumn dataField="specialTypeWork" caption="Özel Çalışma" />
            <DxColumn dataField="sorguKey" caption="Sorgu Anahtarı" />
            <DxColumn dataField="raporKey" caption="Rapor Anahtarı" />

            <DxColumn
              dataField="createdAt"
              caption="Oluşturulma Tarihi"
              dataType="date"
              format="yyyy-MM-dd"
            />
            <DxColumn
              :width="180"
              :fixed="true"
              caption="İşlemler"
              fixedPosition="right"
              cellTemplate="KartTemplate"
            ></DxColumn>
            <template #KartTemplate="{ data }">
              <div class="row">
                <div class="col-12 col-sm-3 mb-2 mb-sm-0">
                  <b-button variant="success" size="sm" @click="runJob(data)">
                    <feather-icon icon="PlayIcon" size="16" />
                  </b-button>
                </div>
                <div class="col-12 col-sm-3 mb-2 mb-sm-0">
                  <!-- <b-button
                    variant="success"
                    size="sm"
                    @click="editJob(data.data)"
                  >
                    <feather-icon icon="EditIcon" size="16" />
                  </b-button> -->
                </div>

                <div class="col-12 col-sm-3 mb-2 mb-sm-0">
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteTask(data.data)"
                  >
                    <feather-icon icon="TrashIcon" size="16" />
                  </b-button>
                </div>
              </div>
            </template>
          </DxDataGrid>
        </b-col>
      </b-row>
    </b-card>
    <!-- Yeni Kayıt Ekle Modal -->
    <b-modal v-model="addTaskModal" title="Yeni Kayıt Ekle" hide-footer>
      <b-form>
        <b-form-group label="Adı">
          <b-form-input
            v-model="record.name"
            placeholder="Adı girin"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Açıklama">
          <b-form-input
            v-model="record.description"
            placeholder="Açıklama girin"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Çalışma Türü">
          <b-form-select
            v-model="record.workType"
            :options="workTypes"
            placeholder="Seçiniz"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Görev Türü">
          <b-form-select
            v-model="record.dutyType"
            :options="dutyTypes"
            placeholder="Seçiniz"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Özel Tür">
          <b-form-select
            v-model="record.specialType"
            :options="specialTypes"
            placeholder="Seçiniz"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Sorgu Parametreleri">
          <b-form-input
            v-model="record.sorguParams"
            placeholder="Sorgu parametrelerini girin"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Özel Çalışma Türü">
          <b-form-input
            v-model="record.specialTypeWork"
            placeholder="Özel çalışma türünü girin"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Sorgu Anahtarı">
          <b-form-input
            v-model="record.sorguKey"
            placeholder="Sorgu anahtarını girin"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Rapor Anahtarı">
          <b-form-input
            v-model="record.raporKey"
            placeholder="Rapor anahtarını girin"
          ></b-form-input>
        </b-form-group>

        <b-button variant="primary" @click="addTaskSend"> Kaydet </b-button>
        <b-button class="float-right" variant="secondary" @click="closeModal">
          İptal
        </b-button>
      </b-form>
    </b-modal>
  </b-overlay>
</template>

<script>
import { DxDataGrid, DxColumn, DxLookup } from "devextreme-vue/data-grid";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { t } from "@/@core/libs/i18n/utils";
export default {
  components: {
    DxDataGrid,
    ToastificationContent,
    DxColumn,
    DxLookup,
  },
  data() {
    return {
      show: false,

      taskList: [], // Sorgu listesini burada tutun
      workTypes: [
        { value: 0, text: "Saatlik" },
        { value: 1, text: "Günlük" },
        { value: 2, text: "Haftalık" },
        { value: 3, text: "Aylık" },
        { value: 4, text: "Özel" },
      ],
      dutyTypes: [{ value: 0, text: "SORGU AL KAYDET" }],
      specialTypes: [
        { value: 0, text: "Tekrar Eden" },
        { value: 1, text: "Çalış Dur" },
        { value: 2, text: "Gecikmeli Çalış" },
      ],
      record: {
        name: "",
        description: "",
        workType: null,
        dutyType: null,
        specialType: null,
        emailParams: "",
        sorguParams: "",
        specialTypeWork: "",
        sorguKey: "",
        raporKey: "",
        userId: 0,
        customerId: 0,
        createdAt: "",
        id: 0,
      },
      addTaskModal: false,
    };
  },
  created() {
    this.getTask();
  },
  methods: {
    async addTaskSend() {
      this.show = true;
      this.$http
        .post("scheduledtask/insertscheduledtaskmodel", this.record)
        .then((response) => {
          this.show = false;
          this.addTaskModal = false;
          this.getTask();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    addTask() {
      this.addTaskModal = true;
      this.record = {
        name: "",
        description: "",
        workType: null,
        dutyType: null,
        specialType: null,
        emailParams: "",
        sorguParams: "",
        specialTypeWork: "",
        sorguKey: "",
        raporKey: "",
        userId: 0,
        customerId: 0,
        createdAt: new Date(),
        id: 0,
      };
    },
    async runJob(e) {
      console.log(e);
      this.show = true;
      let runRequest = {
        name: e.data.name,
        id: e.data.id,
      };

      this.$http
        .post("scheduledtask/runjobmodel", runRequest)
        .then((response) => {
          console.log(response);
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Başarılı`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Zamanlanmış Görev Başarılı Çalıştırıldı..`,
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getTask() {
      this.show = true;
      this.$http
        .get("ScheduledTask/ScheduledTasks?skip=0&take=1000")
        .then((response) => {
          this.show = false;
          this.taskList = response.data.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    deleteTask(data) {
      this.$bvModal
        .msgBoxConfirm("Görevi Silmek İstediğinize Eminmisiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "danger",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.show = true;
            this.$http
              .delete(`scheduledtask/deleteScheduledTaskModel?id=${data.id}`)
              .then((response) => {
                this.taskList = this.taskList.filter((i) => i.id !== data.id);
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Başarılı`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Silme İşlemi Başarılı..`,
                  },
                });
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    closeModal() {
      this.addTaskModal = false;
    },
  },
};
</script>

<style scoped>
.card-title {
  font-weight: bold;
}
</style>
